@import '~styles/variables';

.form-group {
    label {
        cursor: pointer;
        height: 56px;
        padding-left: 20px;
        padding-right: 20px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        &:active,
        &:focus {
            background: $white-hover;
        }
    }

    span {
        color: $black;
        position: relative;
        font-size: 16px;
        line-height: 20px;
        font-weight: normal;
        transition: font-weight .1s, color .1s;
        flex: 1 0 auto;
    }

    svg {
        display: block;
        flex: none;
        opacity: 0;
    }

    input {
        display: none;

        &:checked {
            & ~ span {
                font-weight: 500;
                color: $blue;
            }

            & ~ svg {
                opacity: 1;
            }
        }

        &:disabled {
            & ~ span {
                font-weight: normal;
                color: $grey-text;
            }
        }
    }
  }
  