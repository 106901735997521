@import '~styles/variables';

.login-page {
  background: $white;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  &__header {
    width: 100%;

    @include iphoneX {
      padding-top: 44px;
    }

    .container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;
      flex-wrap: nowrap;
      height: 56px;
      flex-direction: row;
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 44px;
    width: 100%;
    text-align: center;

    .copyright {
      font-size: 12px;
      line-height: 14px;
      color: $black;
      display: inline-block;
      margin-right: 10px;
    }

    .lang-select {
      display: inline-block;
      position: relative;
      cursor: pointer;

      &__selected {
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: $white;

        @media (max-width: $tablet) {
          color: $black;
        }

        span {
          margin-right: 5px;
        }

        svg {
          transform: rotate(-180deg);
          transition: transform 0.1s;

          &.rotate {
            transform: rotateX(0deg);
          }

          path {
            @media (max-width: $tablet) {
              stroke: $black;
            }
          }
        }
      }

      &__list {
        position: absolute;
        bottom: calc(100% + 14px);
        left: -15px;
        opacity: 0;
        pointer-events: none;
        transform: translate(50px);
        transition: opacity 0.1s, transform 0.1s;
        background: $white;
        box-shadow: 0px 18px 50px rgba(34, 34, 34, 0.16);
        border-radius: 1px;
        padding: 4px 0;

        &.lang-select__list_opened {
          opacity: 1;
          pointer-events: all;
          transform: translate(0px);
        }
      }

      &__item {
        font-size: 14px;
        line-height: 26px;
        color: $black;
        text-align: left;
        padding: 4px 20px;
        background: rgba(0, 0, 0, 0);
        transition: background 0.1s;

        &:hover {
          background: $grey-bg;
        }
      }
    }
  }
}

.login-form {
  width: 100%;
  flex: 1 0 auto;

  &__header {
    text-align: center;
    padding-top: 24px;
    padding-bottom: 24px;

    @include iphoneX {
      padding-bottom: 94px;
    }
  }

  &__forgot {
    display: flex;
    justify-content: flex-end;

    & > div {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: $black;
    }

    a {
      font-size: 12px;
      color: $blue;
      text-decoration: underline;
    }
  }

  &__form {
    display: block;
    margin-bottom: 20px;

    & > * {
      margin-bottom: 20px;
    }

    button {
      width: 100%;
      height: 48px;
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  &__footer {
    text-align: center;

    span {
      color: $grey-text;
      font-size: 14px;
      line-height: 26px;
    }

    a {
      font-size: 14px;
      color: $blue;
    }
  }
}
