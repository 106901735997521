@import '~styles/variables';

.no-notifications {
  display: block;
  width: 100%;
  margin: 40px auto;

  &__icon {
    margin: 0 auto 24px;
    border-radius: 50%;
    width: 144px;
    height: 144px;
    background: $white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    svg {
      display: block;
    }
  }

  &__text {
    width: 210px;
    margin: 0 auto;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: $black;
  }
}
