@import '~styles/variables';

.start-page {
  display: block;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $white;
  z-index: 1;
  min-height: 100vh;

  &.start-page_connected {
    .start-page {
      &__header {
        flex: none;
        padding-top: 48px;
      }

      &__loader {
        opacity: 0;
      }

      &__form {
        display: block;
      }
    }
  }

  &.start-page_loading {
    .start-page {
      &__bg {
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(255, 255, 255, 0.92);
        }
      }

      &__loader {
        opacity: 1;
      }

      &__header {
        flex: none;
        padding-top: 48px;
      }

      &__form,
      &__version {
        display: block;
        opacity: 0.1;
      }
    }
  }

  &__bg {
    background: url('~assets/bg-start.svg');
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 2;
  }

  &__content {
    position: relative;
    z-index: 3;
    min-height: 100%;
    display: block;

    @include iphoneX {
      padding-top: 44px;
    }

    .container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      min-height: 100vh;

      @include iphoneX {
        min-height: calc(100vh - 44px);
      }
    }
  }

  &__header {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;
  }

  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 130px;

    img {
      display: block;
      margin: 0 auto;
    }
  }

  &__loader {
    display: block;
    margin-top: 25px;

    .loader {
      margin: 0 auto;
    }
  }

  &__form {
    display: none;
    flex: 1 0 auto;
    padding-top: 30px;

    .button {
      margin-top: 28px;
      width: 100%;
      height: 48px;
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  &__version {
    display: block;
    text-align: center;
    padding-bottom: 20px;
    padding-top: 20px;

    span {
      display: inline-block;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.03em;
      color: $grey-text;
      margin-right: 12px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
