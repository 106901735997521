@import '~styles/variables';

.button {
  min-width: 123px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $blue;
  color: $white;
  padding: 0 16px;
  font-size: 12px;
  font-weight: 500;
  border: 1px transparent solid;
  cursor: pointer;
  transition: all 0.1s ease 0s;

  &:hover {
    background: darken($blue, 5%);
  }

  &_transparent {
    background: $white;
    color: $blue;
    border: 1px solid $blue;

    svg {
      color: $blue;
    }

    &:hover {
      background: $light-blue;
    }
  }

  &_hasIcon {
    span {
      margin-right: 9px;
    }
  }

  &:disabled {
    background: $grey-text;

    &:hover {
      background: $grey-text;
    }
  }
}
