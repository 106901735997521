@import '~styles/variables';

.form-group {
    label {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        min-height: 56px;
        cursor: pointer;
    }

    span {
        display: block;
        width: 100%;
        font-size: 16px;
        line-height: 20px;
        color: $black;
        padding-right: 50px;
        position: relative;

        &:before {
            position: absolute;
            content: '';
            top: calc(50% - 10px);
            right: 0;
            width: 34px;
            height: 20px;
            border-radius: 10px;
            background: $grey-text;
            transition: background .1s;
            z-index: 1;
        }

        &:after {
            position: absolute;
            content: '';
            top: calc(50% - 8px);
            right: 2px;
            width: 16px;
            height: 16px;
            z-index: 2;
            border-radius: 50%;
            background: $white;
            transform: translateX(-14px);
            transition: transform .1s;
        }
    }

    input {
        display: none;

        &:checked + span {
            &:before {
                background: $blue;
            }

            &:after {
                transform: translateX(0px);
            }
        }
    }
}
  