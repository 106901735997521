@import '~styles/variables';

.notification-page {
  // position: fixed;
  // top: 0;
  // left: 0;
  // bottom: 0;
  // right: 0;
  // z-index: 15;
  background: linear-gradient(180deg, #fafafa 0%, #f8f8f8 100%), #ffffff;

  &.success {
    .notification-page {
      &__icon {
        background: #d9fcd6;
        &:before {
          background: #bdf8b8;
        }
        svg {
          stroke: $green;
        }
      }
      &__title {
        color: $green;
      }
    }
  }

  &.warning {
    .notification-page {
      &__icon {
        background: #fff6d5;
        &:before {
          background: #ffedaf;
        }
        svg {
          stroke: $yellow;
        }
      }
      &__title {
        color: $yellow;
      }
    }
  }

  &.error {
    .notification-page {
      &__icon {
        background: #ffebeb;
        &:before {
          background: #ffd8d8;
        }
        svg {
          stroke: $red;
        }
      }
      &__title {
        color: $red;
      }
    }
  }

  &.info {
    .notification-page {
      &__icon {
        background: #dbe7ff;
        &:before {
          background: #c8daff;
        }
        svg {
          stroke: $blue;
        }
      }
      &__title {
        color: $blue;
      }
    }
  }

  &.hint {
    .notification-page {
      &__icon {
        background: #f1f1f1;
        &:before {
          background: #e2e2e2;
        }
        svg {
          stroke: $grey-text;
        }
      }
      &__title {
        color: $grey-text;
      }
    }
  }

  &__bg {
    background: url(~assets/bg-notification.svg);
    background-position: center top;
    background-repeat: no-repeat;
    position: absolute;
    top: 73px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;

    @include iphoneX {
      top: 117px;
    }
  }

  &__content {
    z-index: 2;
    position: relative;
  }

  &__header {
    @include iphoneX {
      padding-top: 44px;
    }

    .container {
      min-height: 56px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }

    .close {
      display: block;
      position: relative;
      right: -6px;

      svg {
        display: block;
      }
    }
  }

  &__icon {
    display: block;
    width: 116px;
    height: 116px;
    margin: 0 auto;
    margin-top: 54px;
    border-radius: 50%;
    background: #f1f1f1;
    position: relative;

    &:before {
      position: absolute;
      z-index: 1;
      width: 86px;
      height: 86px;
      top: calc(50% - 43px);
      left: calc(50% - 43px);
      border-radius: 50%;
      background: #e2e2e2;
      content: '';
    }

    svg {
      display: block;
      position: absolute;
      z-index: 2;
      top: calc(50% - 18px);
      left: calc(50% - 18px);
    }
  }

  &__title {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    text-transform: uppercase;
    margin-top: 16px;
  }

  &__dots {
    display: block;
    width: 100%;
    background-image: url("data:image/svg+xml,%3Csvg width='32' height='4' viewBox='0 0 32 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='2' cy='2' r='2' fill='%23D9D9D9'/%3E%3Ccircle cx='16' cy='2' r='2' fill='%23D9D9D9'/%3E%3Ccircle cx='30' cy='2' r='2' fill='%23D9D9D9'/%3E%3C/svg%3E%0A");
    height: 4px;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  &__date {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 12px;
    text-align: center;
    color: $black;
  }

  &__message {
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: $black;
  }
}
