@import '~styles/variables';

.lang-page {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $white;
    z-index: 2;
  }

  &__content {
    z-index: 3;
    position: relative;
  }

  &__header {
    @include iphoneX {
      padding-top: 44px;
    }

    &.lang-page__header_scroll {
      box-shadow: 0px 1px 16px rgba(34, 34, 34, 0.1);
    }

    .container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;
      flex-wrap: nowrap;
      height: 56px;
    }

    .arrow {
      display: block;
      margin-left: -6px;
      margin-right: 6px;

      svg {
        display: block;
      }
    }

    .close {
      display: block;
      margin-right: -6px;
      margin-left: 6px;

      svg {
        display: block;
      }
    }

    .text {
      display: block;
      flex: 1 0 auto;
      text-align: left;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: $black;
    }
  }

  &__list {
    position: relative;
    max-height: calc(100vh - 56px);
    min-height: calc(100vh - 56px);
    overflow-y: scroll;

    @include iphoneX {
      max-height: calc(100vh - 100px);
      min-height: calc(100vh - 100px);
    }

    p {
      font-size: 13px;
      line-height: 20px;
      color: $grey-text;
      margin: 0 0 16px;
    }
  }
}
