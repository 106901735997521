@import '~styles/variables';

.form-group {
  position: relative;

  label {
    color: $grey-text;
    font-size: 11px;
    display: block;
    margin-bottom: 9px;
    text-transform: uppercase;
  }

  input {
    background: $white;
    border: 1px solid $grey;
    box-sizing: border-box;
    border-radius: 1px;
    height: 48px;
    width: 100%;
    padding-left: 14px;
    padding-right: 48px;
    font-size: 14px;
    color: $black;
    transition: border 0.1s;

    &:focus {
      outline: none;
      border: 1px solid $blue;
    }
  }

  & > div {
    position: relative;

    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 18px;
      pointer-events: none;
    }
  }

  &_error {
    label {
      color: $red;
    }

    input {
      border: 1px solid $red;

      &:focus {
        border-color: $red;
      }
    }
  }

  &__errorText {
    color: $red;
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-top: 14px;
    margin-bottom: 30px;

    svg {
      color: $red;
      margin-right: 6px;
    }
  }
}
