@import '~styles/variables';

.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  &__header {
    @include iphoneX {
      padding-top: 44px;
    }

    &.lang-page__header_scroll {
      box-shadow: 0px 1px 16px rgba(34, 34, 34, 0.1);
    }

    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 56px;
    }

    .arrow {
      display: block;
      margin-left: -6px;
      margin-right: 6px;

      svg {
        display: block;
      }
    }

    .close {
      display: block;
      margin-right: -6px;
      margin-left: 6px;

      svg {
        display: block;
      }
    }

    .text {
      display: block;
      flex: 1 0 auto;
      text-align: left;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: $black;
    }
  }

  &__dialog {
    box-shadow: 5px 0 14px 10px rgba(0, 0, 0, 0.2);
    background: url(~assets/bg-menu.svg);
    position: relative;
    z-index: 1;
    min-height: 100vh;
  }
}
