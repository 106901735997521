@import '~styles/variables';

.top-menu {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: $tr;
  box-shadow: 0px 1px 16px $tr;
  transition: background 0.2s, box-shadow 0.2s;
  z-index: 5;

  @include iphoneX {
    padding-top: 44px;
  }

  &.scroll {
    background: $white;
    box-shadow: 0px 1px 16px rgba(34, 34, 34, 0.1);

    .top-menu {
      &__user,
      &__balance {
        pointer-events: all;
        opacity: 1;
      }
    }
  }

  &.message {
    .top-menu {
      &__hamburger {
        span {
          opacity: 1;
        }
      }
    }
  }

  .container {
    height: 56px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  &__user {
    display: block;
    width: 36px;
    height: 36px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.1s;
  }

  &__balance {
    display: block;
    flex: 1 0 auto;
    padding-left: 12px;
    padding-right: 12px;
    white-space: nowrap;
    max-width: calc(100% - 60px);
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: $black;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.1s;
  }

  &__hamburger {
    position: relative;

    svg {
      display: block;
      position: relative;
    }

    span {
      width: 10px;
      height: 10px;
      position: absolute;
      left: 0;
      top: 0;
      background: $red;
      border: 2px solid $white;
      border-radius: 50%;
      opacity: 0;
      transition: opacity 0.1s;
    }
  }
}
