@import '~styles/variables';

.top-hint {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-100%);
  transition: transform 0.1s ease;

  @include iphoneX {
    padding-top: 44px;
  }

  &.info {
    background: $dark-blue;
  }

  &.error {
    background: $red;
  }

  &.show {
    transform: translateY(0);
  }

  &__content {
    display: flex;
    min-height: 56px;
    color: $white;
    align-items: center;
  }

  &__text {
    flex: 1 0 auto;
    max-width: calc(100% - 48px);
    padding-left: 14px;
    padding-right: 14px;
  }
}
