.loader {
  width: 40px;
  height: 40px;
  background: #ffffff;
  border-radius: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  svg {
    display: block;
    animation: loader-spin 2s linear forwards infinite;
    margin: 0 !important;

    circle {
      stroke-dasharray: 100;
      animation: loader-dash 2s linear forwards infinite;
    }
  }
}

@keyframes loader-dash {
  from {
    stroke-dashoffset: 100;
  }
  to {
    stroke-dashoffset: -70;
  }
}

@keyframes loader-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
