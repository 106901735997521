$blue: #025dff;
$light-blue: #e3ecff;
$dark-blue: #22324E;
$red: #ff3636;
$green: #149c08;
$yellow: #FF9C08;
$white: #fff;
$white-hover: transparentize(#f6f6f6, .2);
$black: #222;
$grey: #ddd;
$grey-text: #828282;
$grey-bg: #f4f4f4;
$tr: rgba(0,0,0,0);

@mixin rubik {
  font-family: 'Rubik', sans-serif;
}

@mixin code {
  font-family: 'Source Code Pro', monospace;
}

// media sizes
$desktop: 1140px;
$laptop: 1023px;
$tablet: 768px;
$phone: 480px;

@mixin iphoneX {
  @media only screen and (min-device-width : 375px) and (device-height : 812px) and (-webkit-device-pixel-ratio : 3), only screen and (device-width : 414px) and (device-height : 896px) and (-webkit-device-pixel-ratio : 2), only screen and (device-width : 414px) and (device-height : 896px) and (-webkit-device-pixel-ratio : 3) {
    @content;
  }
}