@import '~styles/variables';

.menu-page {
  &__content {
    z-index: 3;
    position: relative;
  }

  &__list {
    position: relative;
    max-height: calc(100vh - 112px);
    min-height: calc(100vh - 112px);
    overflow: auto;

    @include iphoneX {
      max-height: calc(100vh - 156px);
      min-height: calc(100vh - 156px);
    }
  }

  &__item {
    display: block;
    background: $tr;
    transition: background 0.1s;

    &:active,
    &:focus {
      background: $white-hover;
    }

    &.menu-page__item_message {
      .icon {
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 10px;
          height: 10px;
          border: 2px solid $white;
          background: $red;
          border-radius: 50%;
          box-sizing: border-box;
        }
      }
    }

    .container {
      display: flex;
      min-height: 70px;
      justify-content: flex-start;
      align-items: center;
    }

    .icon,
    .link {
      position: relative;

      svg {
        display: block;
      }
    }

    .link {
      right: -6px;

      svg {
        stroke: $grey-text;
      }
    }

    .text {
      flex: 1 0 auto;
      padding-left: 20px;
      padding-right: 20px;
      max-width: calc(100% - 48px);
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: $black;

      span {
        font-weight: 400;
      }
    }
  }

  &__footer {
    height: 56px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.03em;
    color: $grey-text;

    span {
      margin-right: 12px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
