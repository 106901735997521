@import './reset';
@import './variables';

#root,
body,
html {
  height: 100%;
}

#modal-root {
  position: relative;
  z-index: 1050;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: $black;
}

* {
  box-sizing: border-box;
}

h1 {
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: $black;
}

h2 {
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  color: $black;
}

h3 {
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  color: $black;
}

p {
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: $black;
}

button {
  background: none;
  font-family: 'Rubik', sans-serif;
  border: none;
  padding: 0;
  margin: 0;
  font-weight: normal;

  &:focus,
  &:active {
    outline: none;
  }
}

a {
  transition: all 0.1 ease 0s;
  color: initial;
  text-decoration: none;

  &:hover {
    opacity: 0.8;
  }
}

img {
  max-width: 100%;
  height: auto;
}

textarea,
input {
  @include rubik;
}

code {
  @include code;
}

.container {
  width: 100%;
  display: block;
  padding-right: 16px;
  padding-left: 16px;
  margin: 0;

  @include iphoneX {
    padding-left: 20px;
    padding-right: 20px;
  }
}
