@import '~styles/variables';

.main-page {
  display: block;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $white;
  z-index: 1;
  min-height: 100vh;

  &__bg {
    background: url('~assets/bg-main.svg');
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 2;
  }

  &__content {
    position: relative;
    z-index: 3;
    padding-top: 79px;
    padding-bottom: 16px;
    max-height: 100vh;
    overflow: auto;

    @include iphoneX {
      padding-top: 123px;
      padding-bottom: 20px;
    }

    .container {
      text-align: center;
    }
  }

  &__date {
    font-size: 14px;
    line-height: 17px;
    color: $black;
    padding-bottom: 16px;
    text-align: center;
  }

  &__balance {
    font-weight: bold;
    font-size: 36px;
    line-height: 43px;
    color: $black;
    text-align: center;
    padding-bottom: 26px;
  }

  &__user {
    display: inline-flex;
    max-width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 54px;
    background: #ffffff;
    box-shadow: 0px 2px 6px #e9e9e9;
    border-radius: 40px;
    padding-left: 14px;
    padding-right: 14px;

    .icon {
      width: 36px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      background: $blue;
      border-radius: 50%;
      color: #fff;
      font-size: 14px;
    }

    .text {
      margin-left: 8px;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: $black;
      max-width: calc(100% - 44px);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  &__list {
    padding-top: 34px;
  }

  &__title {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: $black;
    margin-bottom: 18px;
    text-align: left;
  }
}
